import React from "react";
import { Container, Row, Col, ProgressBar } from "react-bootstrap";

const Skills = () => {
    const skillsData = [
        { skill: "React", level: 70 },
        { skill: "JavaScript", level: 70 },
        { skill: "HTML 5", level: 80 },
        { skill: "CSS", level: 80 },
        { skill: "React Bootstrap", level: 70 },
        { skill: "Responsive Design", level: 90 },
        { skill: "Version Control (GitHub)", level: 70 },
        { skill: "Firebase", level: 60 },
        { skill: "Tailwind", level: 50 },
    ];

    return (
        <section id="skills" className="py-5  text-danger">
            <Container>
                <h2 className="text-center mb-4">Skills</h2>
                <Row>
                    {skillsData.map((skill, index) => (
                        <Col md={6} key={index}>
                            <div className="mb-4">
                                <h5>{skill.skill}</h5>
                                <ProgressBar
                                    now={skill.level}
                                    label={`${skill.level}%`}
                                />
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
        </section>
    );
};

export default Skills;
