import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

const Education = () => {
    const educationData = [
        {
            Degree: "Bachelor of Science",
            Institute: "Daffodil International University",
            Subject: "Computer Science and Engineering",
            CGPA: "3.27 out of 4.00",
            year: "Graduated in 2022",
        },
        {
            Degree: "Higher Secondary Certificate",
            Institute: "Goverment KC College, Jhenaidah",
            Subject: "Science",
            CGPA: "4.33 out of 5.00",
            year: "Completed in 2017",
        },
        {
            Degree: "Secondary School Certificate",
            Institute: "Kanchannagar Model High School, Jhenaidah",
            Subject: "Science",
            CGPA: "5.00 out of 5.00",
            year: "Completed in 2015",
        },
    ];

    return (
        <section id="education" className="py-5">
            <Container>
                <h2 className="text-center mb-4 text-danger">Education</h2>
                <Row>
                    {educationData.map((education, index) => (
                        <Col md={4} key={index}>
                            <Card className="mb-4">
                                <Card.Body>
                                    <h5>{education.Degree}</h5>
                                    <p className="mb-2">
                                        {education.Institute}
                                    </p>
                                    <p className="mb-0">{education.Subject}</p>
                                    <p className="mb-0">{education.CGPA}</p>
                                    <p className="mb-0">{education.year}</p>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        </section>
    );
};

export default Education;
