import "./App.css";
import HomePage from "./components/HomePage/HomePage";
import NavbarComponent from "./components/Navbar/Navbar";

function App() {
    return (
        <div className="App">
            <NavbarComponent></NavbarComponent>
            <HomePage></HomePage>
        </div>
    );
}

export default App;
