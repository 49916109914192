import React from "react";
import Banner from "../Banner/Banner";
import AboutMe from "../AboutMe/AboutMe";
import Skills from "../Skill/Skill";
import Education from "../Education/Education";
import ContactMe from "../ContactMe/ContactMe";
import Footer from "../Footer/Footer";

const HomePage = () => {
    return (
        <div style={{ paddingTop: "150px" }}>
            <Banner></Banner>
            <AboutMe></AboutMe>
            <Skills></Skills>
            <Education></Education>
            <ContactMe></ContactMe>
            <Footer></Footer>
        </div>
    );
};

export default HomePage;
