import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
    FaFacebook,
    FaTwitter,
    FaLinkedin,
    FaGithub,
    FaInstagram,
} from "react-icons/fa";

const Footer = () => {
    const socialLinks = [
        {
            icon: FaFacebook,
            link: "https://www.facebook.com/profile.php?id=100009552123285",
        },
        { icon: FaTwitter, link: "https://twitter.com/ArindomKundu12" },
        {
            icon: FaLinkedin,
            link: "https://www.linkedin.com/in/arindom-kundu-402277184/",
        },
        { icon: FaGithub, link: "https://github.com/arindom10" },
        { icon: FaInstagram, link: "https://www.instagram.com/pollab_kundu/" },
    ];

    return (
        <footer className="py-4 text-center">
            <Container>
                <Row>
                    <Col>
                        <div className="social-icons m-auto">
                            {socialLinks.map((social, index) => (
                                <a
                                    key={index}
                                    href={social.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <social.icon className="icon m-lg-2 text-success" />
                                </a>
                            ))}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p className="mt-3">
                            Copyright © 2023 Arindom Kundu. All rights reserved.
                        </p>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;
