import React from "react";
import { Button, Container, Nav, Navbar } from "react-bootstrap";

const NavbarComponent = () => {
    return (
        <Navbar bg="light" expand="lg" className="position-fixed w-100">
            <Container>
                {/* Left side: Logo and Name */}
                <Navbar.Brand href="#home">
                    <img
                        src="https://i.ibb.co/nr5R9SX/1.png" // Replace with your logo image URL
                        width="80"
                        height="40"
                        className="d-inline-block align-top"
                        alt="Logo"
                    />
                </Navbar.Brand>

                {/* Middle: Links */}
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link className="text-success" href="#home">
                            Home
                        </Nav.Link>
                        <Nav.Link className="text-success" href="#aboutMe">
                            About Me
                        </Nav.Link>
                        <Nav.Link className="text-success" href="#skills">
                            Skill
                        </Nav.Link>
                        <Nav.Link
                            className="text-success"
                            href="https://drive.google.com/file/d/1G_xXseL2oXmzUQy1MinpUuyTx05GRQ77/view?usp=sharing"
                            target="_blank"
                        >
                            Resume
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>

                {/* Right side: Contact Link */}
                <Nav>
                    <Button variant="success" href="#contact">
                        Contact Me
                    </Button>
                </Nav>
            </Container>
        </Navbar>
    );
};

export default NavbarComponent;
