import React from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
const LinkedinLink = "https://www.linkedin.com/in/arindom-kundu-402277184/";
const Banner = () => {
    return (
        <div id="home">
            <section className="hero">
                <Container>
                    <Row className="align-items-center">
                        <Col lg={6}>
                            <div className="hero-content">
                                <h1>
                                    My Name Is{" "}
                                    <span className="text-danger">
                                        Arindom Kundu
                                    </span>{" "}
                                    & I'm Your Web Developer
                                </h1>
                                <p>
                                    I am a professional web developer and
                                    designer. I will develop any kind of website
                                    from scratch according to your requirement.
                                </p>
                                <Button
                                    variant="danger"
                                    href={LinkedinLink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Let's Talk
                                </Button>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <Image
                                src="https://i.ibb.co/5KfF2tk/2023-02-09-09-27-IMG-1269-removebg-preview.png"
                                alt="Your Photo"
                                fluid
                            />
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    );
};

export default Banner;

/* 
https://i.ibb.co/kJD6CNY/Pic-P2.png */
