import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

const AboutMe = () => {
    return (
        <div id="aboutMe" style={{ paddingTop: "150px" }}>
            <section id="about" className="py-5">
                <Container>
                    <Row className="align-items-center">
                        <Col md={6}>
                            <Image
                                src="https://i.ibb.co/T2ffH5j/IMG-0209.jpg"
                                alt="Profile Image"
                                fluid
                                roundedCircle
                                className="mb-4"
                            />
                        </Col>
                        <Col md={6}>
                            <h2 className="mb-3 text-danger">About Me</h2>
                            <p>
                                Hey there! I'm{" "}
                                <span className="text-danger ">
                                    Arindom Kundu
                                </span>
                                , a passionate Web Developer based in Dhaka.
                                With a strong love for creating seamless,
                                interactive, and visually stunning web
                                applications, I dive headfirst into every
                                project.
                            </p>
                            <p>
                                My journey into the world of web development
                                began with my fascination for user interfaces
                                and a knack for problem-solving. I specialize in
                                crafting dynamic front-end experiences using
                                React, where I bring designs to life with clean
                                and efficient code.
                            </p>
                            <p>
                                Beyond coding, you'll find me staying up-to-date
                                with the latest front-end trends and exploring
                                ways to optimize user experiences. I thrive in
                                collaborative environments, where I enjoy
                                brainstorming ideas, collaborating on projects,
                                and contributing innovative solutions.
                            </p>
                            <p>
                                When I'm not in front of a screen, I'm likely to
                                be found my passion for coding with the
                                community. I believe in lifelong learning and
                                constantly strive to enhance my skills,
                                delivering the best possible results for every
                                project I work on.
                            </p>
                            <p>I have a Team and specialize in,</p>
                            <p>
                                E-commerce, WordPress, Shopify, Woo-commerce,
                                Landing pages, Bigcommerce, Dropshipping,
                                Multivendor, and Wholesale websites, Business
                                Websites, Amazon ALI Express/Alibaba, and eBay
                                Affiliate store sites. Also, I have a Design
                                team to make your work easier.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    );
};

export default AboutMe;
