import React, { useRef } from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import emailjs from "@emailjs/browser";
import {
    FaWhatsapp,
    FaLinkedin,
    FaTelegram,
    FaGithub,
    FaFacebook,
    FaTwitter,
} from "react-icons/fa";

const ContactMe = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(
                "service_6vvsmye",
                "template_q36l6zh",
                form.current,
                "oQV5S5tr3bznn6rIo"
            )
            .then(
                (result) => {
                    console.log(result.text);
                },
                (error) => {
                    console.log(error.text);
                }
            );
    };
    const contactLinks = [
        {
            platform: "WhatsApp",
            icon: FaWhatsapp,
            link: "https://wa.me/01716213091",
        },
        {
            platform: "LinkedIn",
            icon: FaLinkedin,
            link: "https://www.linkedin.com/in/arindom-kundu-402277184/",
        },
        {
            platform: "Telegram",
            icon: FaTelegram,
            link: "https://t.me/arindom10557",
        },
        {
            platform: "GitHub",
            icon: FaGithub,
            link: "https://github.com/arindom10",
        },

        {
            platform: "Facebook",
            icon: FaFacebook,
            link: "https://www.facebook.com/profile.php?id=100009552123285",
        },
        {
            platform: "Twitter",
            icon: FaTwitter,
            link: "https://twitter.com/ArindomKundu12",
        },
    ];

    return (
        <section id="contact" className="py-5">
            <Container>
                <h2 className="text-center mb-4 text-danger">Contact Me</h2>
                <Row className="justify-content-center">
                    {contactLinks.map((contact, index) => (
                        <Col
                            xs={6}
                            sm={4}
                            md={3}
                            lg={2}
                            key={index}
                            className="mb-3"
                        >
                            <a
                                href={contact.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="contact-link text-decoration-none text-success"
                            >
                                <contact.icon className="contact-icon" />
                                <span className="contact-platform">
                                    {contact.platform}
                                </span>
                            </a>
                        </Col>
                    ))}
                </Row>
                <Row sx={1} md={2} className="mt-5">
                    <Col className="mx-auto">
                        <h2 className="text-danger">Google Map Location</h2>
                        <iframe
                            width="350"
                            height="350"
                            id="gmap_canvas"
                            src="https://maps.google.com/maps?q=shymoli, kamal soroni, momtaz mansion&t=&z=18&ie=UTF8&iwloc=&output=embed"
                            frameborder="0"
                            scrolling="no"
                            marginheight="0"
                            marginwidth="0"
                        ></iframe>
                    </Col>
                    <Col>
                        <Card
                            style={{ maxWidth: "350px" }}
                            className="mx-auto border-0 shadow-lg p-3"
                        >
                            <Form ref={form} onSubmit={sendEmail}>
                                <Form.Group
                                    className="mb-2 text-start"
                                    controlId="formBasicEmail"
                                >
                                    <Form.Control
                                        required
                                        type="text"
                                        name="form_name"
                                        placeholder="your name"
                                        className="mt-3"
                                    />
                                </Form.Group>
                                <Form.Group
                                    className="mb-2 text-start"
                                    controlId="formBasicEmail"
                                >
                                    <Form.Control
                                        required
                                        type="email"
                                        name="form_email"
                                        placeholder="your email"
                                        className="mt-3"
                                    />
                                </Form.Group>

                                <Form.Group
                                    className="mb-3 text-start"
                                    controlId="formBasicEmail"
                                >
                                    <Form.Control
                                        required
                                        type="text"
                                        name="form_subject"
                                        placeholder="Subject"
                                        className="mt-3"
                                    />
                                </Form.Group>
                                <Form.Group
                                    className="mb-3 text-start"
                                    controlId="formBasicEmail"
                                >
                                    <Form.Control
                                        required
                                        as="textarea"
                                        rows={3}
                                        name="message"
                                        placeholder="Body"
                                        className="mt-3"
                                    />
                                </Form.Group>
                                <Button
                                    type="submit"
                                    className="btn btn-primary rounded-pill mt-4"
                                >
                                    Send E-mail
                                </Button>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default ContactMe;
